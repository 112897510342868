*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.item-container{
    max-width: 1400px;
    margin: 0 auto;
}
@media (max-width: 749.8px) {
    .item-col-5{
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important; 
        max-width: 100% !important;
        padding:  0 1rem !important;
        margin: 10px 0;
    }
    .item-row{
        width: 100% !important;
        padding: 0 !important;
    }
  }
.item-conter {
    overflow: hidden;
    font-size: 1.1rem;
}
.item-next{
    padding: 1rem 0 1rem 1rem;
}
.item-row{
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 10px;
    margin: 0 auto;
    max-width: 1400px;
}
.item-col-5{
    -ms-flex: 0 0 48%;
    flex: 0 0 48%;
    max-width: 48%;
}
.item-img{
    width: 100% !important;
    height: 350px !important;
    object-fit:contain;
}
.item-title{
    opacity: 0.8;
    font-size: 1.7rem;
}
.item-place{
    padding: 1rem 0;
    opacity: 0.7;
    font-size: 1rem;
}
.money-item{
    padding: 1rem 0;
    border-bottom: 1px solid #4a4a4a ;
}
.item-money{
    color: #7fac51;
    font-size: 16px;
    padding-left: 1.5rem;
}
.item-price{
    font-size: 16px;
}
.axis-vertical{
    display: none;
}
.item-a{
    text-decoration:none;
    color: black;
}
.detail-btn-create-update {
    display: flex;
    justify-content: center;
}
.carousel-status{
    display: none !important;
}
.control-dots{
    display: none;
}