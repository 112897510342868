
.navbar {
    width: 100%;
    /* height: 80px; */
    position: sticky;
    top: 0;
    /* background: #ff7600; */
    background: rgba(69,71,68,0.95);
    z-index: 99;
    display: flex;
    justify-content: space-between;
    /* line-height: 80px; */
}
  .navbar-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  #nav__btn-action{
    width: 100%;
    color: white;
    display: flex;
    /* justify-content: space-around !important; */
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 500px) {
    #nav__btn-action{
      justify-content: space-between;
    }
  }

  @media (max-width: 414px) {
    .nav-bar-btn-admin {
      font-size: 1rem !important;
      /* margin: 0 12px !important; */
    }
    .logo{
      margin-left: 10px !important;
      display: block !important;
      width: auto !important;
      height: 80px !important;
    }
    .logo-long{
      display: none !important;
    }
    .nav-bar-left{
      max-height: 80px;
    }
  }

  @media (max-width: 375px) {
    .nav-bar-btn-admin {
      font-size: 0.85rem !important;
      /* margin: 0 12px !important; */
    }

    .logo{
      margin-left: 10px;
      display: block !important;
      width: auto !important;
      height: 80px !important;
    }
    .logo-long{
      display: none !important;
    }
  }

  @media (max-width: 320px) {
    .nav-bar-btn-admin {
      font-size: 0.8rem !important;
      /* margin: 0 7px !important; */
    }
    .logo{
      margin-left: 5px !important;
      display: block !important;
      width: auto !important;
      height: 80px !important;
    }
    .logo-long{
      display: none !important;
    }
  }

  #nav__btn-action span:hover{
    color: #C4E538;
    cursor: pointer;
  }
  
  .btn-link-change{
    margin: 0 20px 0 0 !important;
    display: inherit;
  }
  .navbar-list div {
    font-size: 16px;
    color: white;
  }
  .navbar-list div:hover {
    color: #C4E538;
  }
  .navbar-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .navbar-list div button {
    width: 150px;
    height: 50px;
    border-radius: 30px;
    border: none;
    background: #79AD3D;
    font-size: 16px;
    color: white;
    opacity: initial;
  }
  .navbar-list div button:hover {
    background: #C4E538;
  }
  .navbar-list div button:focus {
    outline: none;
  }
  .nav-bar-btn-admin {
    font-weight: bold;
    color: white !important;
    font-size: 1.2rem;
    /* margin: 0 15px;
    padding: 0 15px; */
  }
  .menu{
    position: sticky;
    z-index: 100;
    top: 80px
  }
  .menu button:hover {
    background-color: #C4E538;
    color: white;
  }
  .menu button:focus {
    background-color: #A3CB38;
    outline: none;
    color: white;
  }
  .bg-menu-button {
    background-color: #A3CB38 !important;
    color: white;
  }
  .btn-admin {
    
    margin: 100px 0 0 20px;
    height: 40px;
    display: flex;
    align-content: center;
  }
  .btn-admin button {
    color: white;
    background: #79AD3D;
    width: 120px;
    border-radius: 8px;
    border: none;
  }
  .btn-admin button:focus {
    outline: none;
  }
  .btn-admin button:hover {
    background-color: #C4E538;
    color: white;
  }
  @media (min-width: 421px) {
    .menu {
      background-color: #f1f2f6;
      height: 50px;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
    .menu button {
      font-size: 20px;
      height: 100%;
      width: 170px;
      margin-right: 10px;
      background: none;
      border: none;
    }
  }
  @media (max-width: 420px) {
    .menu {
      background-color: #f1f2f6;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-around;
    }
    .menu button {
      font-size: 15px;
      height: 40px;
      width: 150px;
      background: none;
      border: none;
    }
    .navbar-list div button {
      display: none
    }
  }
  .home {
    margin: 0 auto;
  }
  .parent-home-page {
    margin: 0 auto;
    height: 100%;
  }
  .home-page {
    /* width: 100%; */
    height: 100%;
    background-image: url('../img/trongcay.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    
  }
  .home-page-content {
    padding: 0 20px 0 20px;
    /* width: 100%; */
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(37,39,37,.5);
  }
  .content-title {
    position: absolute;
    top: 150px;
  }
  .content-title h1 {
    margin: 0 !important;
  }
  .btn-of-admin {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .btn-google-form {
    line-height: 30px;
    padding: 0 20px;
    background: #A3CB38;
    color: white;
    border-radius: 5px;
  }
  .component-btn-join {
    max-width: 180px !important;
  }
  .pagination {
    margin: 50px 0 50px 0 !important;
    display: flex;
    justify-content: center;
  }
  .content-title h1 {
    max-width: 500px;
    color: white;
    font-size: 3rem;
  } 
  .content-team {
    /* height: 100px; */
    padding: 0 20px;
    border: white solid 2px;
    border-radius: 50px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    position: absolute;
    bottom: 40px;
    /* left: 50%;
    transform: translate(-50%,0);
    max-width: calc(100% - 40px);
    width: fit-content; */
  }
  .content-team p {
    text-align: center;
    line-height: 35px;
    margin: 0 !important;
    padding: 15px 0;
  }
  @media (max-height: 500px) {
    .parent-home-page {
      height: 1000px;
    }
    .content-team {
      position: relative;
      width: 80%;
      margin-top: 300px;
      font-size: .8rem;
    }
  }
  @media (min-height: 730px) {
    .content-title {
      top: 300px;
    }
  }
  @media (max-width: 350px) {
    .content-team {
      font-size: .58em !important;
      /* height: 60px !important; */
      /* width: calc(100% - 20px);s */
    }
    .content-team p {
      line-height: 20px;
    }
  }
  @media (max-width: 500px) {
    .content-title h1 {
      font-size: 2em !important;
    }
    .content-team {
      font-size: .8em ;
      /* height: 80px ; */
      /* width: calc(100% - 20px); */
    }
    
    .home-page-content {
      padding: 0 10px 0 10px;
    }
  }
  .plant-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .footer {
    background-image: url('../img/watermark_26072019105917273-160913.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  
  .footer div {
    height: 100px;
    text-align: center;
    line-height: 100px;
  }
  .footer input {
    height: 40px;
    width: 200px;
    padding-left: 10px;
    border-radius: 10px 0 0 10px;
    border: none;
  }
  .footer input:focus {
    outline: none;
  }
  .footer button {
    height: 42px;
    width: 80px;
    border: none;
    border-radius:  0 10px 10px 0;
    background: #79AD3D;
    color: white;
  }
  .footer button:focus {
    outline: none;
  }
  .footer button:hover {
    background: #C4E538;
  }
  .footer-text {
    color: white;
  }

  .plant-list{
    width: 90%;
    margin: 0 auto;
    max-width: 1400px;
  }

  .plant .thumbail{
    width: 100%;
    object-fit: cover;
    height: 209px;
  }

  .plant-desc{
      margin-bottom: 10px;
      min-height: 66px;
      font-size: 1rem;
      height: 80px;
  }

  .plant-name{
    min-height: 60px;
    font-size: 18px;
    height: 10px;
  }

  .plant .ant-card-body{
    padding: 12px;
  }

  .plant-cost{
    color: #79AD3D;
    margin: 0;
    display: none !important;
  }

  .center-vertical{
    display: flex !important;
    /* display: none !important; */
    align-items: center;
  }

  .nav-bar-left{
    display: flex;
    /* justify-content: space-between; */
    justify-content: space-around;
    align-items: center;
    width: 40%;
    flex-wrap: nowrap;
  }

  .logo{
    width: auto;
    height: 80px;
    margin-left: 50px;
  }
  .none{
    display: none;
  }
  @media (max-width: 950.8px) {
    .logo{
      margin-left: 20px;
      display: block !important;
      width: auto !important;
      height: 80px !important;
    }
    .logo-long{
      display: none !important;
    }
    .nav-bar-left{
      height: 80px;
      width: 100%;
    }
    .nav__btn-action{
      height: 80px;
    }
    .nav-logo{
      width: auto;
    }
  }