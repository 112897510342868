.btn-modal-create {
    text-align: end;
    margin-right: 10px;
}
.ant-btn-primary {
    border-radius: 5px !important;
    background-color: #A3CB38 !important;
    border: none !important;
}
.ant-btn-primary:hover {
    background-color: #C4E538 !important;
}
.content-create {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.content-create textarea {
    border: 2px solid #79AD3D;
    height: 40px;
    padding: 5px;
    margin: 10px 0 10px 0;
    border-radius: 5px;
}
.content-create textarea:focus {
    outline: none;
    background-color: honeydew;
}
.content-create textarea:hover {
    background-color: honeydew;
}
.content-create select {
    height: 40px;
    padding-left: 5px;
    border-radius: 5px;
    border: 2px solid #79AD3D;
}
.content-create select:focus {
    outline: none;
    background-color: honeydew;
}
.content-create select:hover {
    background-color: honeydew;
}
.content-create div {
    text-align: center;
}
.content-create button {
    margin-top: 20px;
    height: 40px;
    border-radius: 5px;
    border: none;
    color: white;
    background: #79AD3D;
    font-size: 15px;
}
.content-create button:focus {
    outline: none;
}
.content-create button:hover {
    background-color: #C4E538;
    color: white;
}
