  .wp-login{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .form__login{
    padding-top: 15px;
    width: 50%;
    height: 350px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
    margin: 0 auto;
  }
  .form__container{
    width: 80%;
    margin: 0 auto;
  }
  .form__login-input{
    width: 100%;
    height: 40px;
    border-radius: 6px;
    border: 1px solid #dddfe2;
    margin-top:5px ;
  }
  .btn-login{
    width: 80%;
    height: 40px;
    margin: 0 10%;
    margin-top:5px ;
    border: 1px solid #dddfe2;
    border-radius: 6px;
    font-size: 20px;
  }
  
  @media (max-width: 421px) {
    .form__login{
      width: 80%;
    }
  }