* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #e6e6e6;
}

.content {
    margin: 24px 0px;
    width: 100%;
}

.item-content {
    padding: 20px 5%;
    text-align: justify;
}

.page {
    padding: 0px 12px;
    margin: 4px;
}

.btn-modal-create {
    display: flex;
    justify-content: center;
}

.pageList {
    display: flex;
    justify-content: center;
    margin: 24px 0px;
}

.image img {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 20px 0px;
}

.content img {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 20px 0px;
}

.title,
figcaption {
    text-align: center;
}

.deleteBtn {
    background-color: red !important;
}

.btnGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.background {
    width: 70%;
    background-color: white;
    min-height: 100vh;
}

@media (max-width: 375px) {
    .item-content {
        padding: 20px 20px;
    }
}